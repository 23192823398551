// IMPORTS
// ------------------------------------------
@import "common/variables.scss";
@import "common/text.scss";
@import "common/mixins.scss";

@import "components/video.scss";

@import "layouts/pages.scss";

// GENERAL
// ------------------------------------------
img {max-width: 100%; height:auto; }
body.single {

	.alignleft {
		display: inline;
		width: 45%;
		float: left;
		margin: 20px 20px 20px -50px;
	}

	.alignright {
		display: inline;
		width: 45%;
		float: right;
		margin: 20px -50px 20px 20px;
	}

	.aligncenter {
		clear: both;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

}

ul {margin:0 0 15px 30px; padding:0;}

// ANIMATION GLOBALS - Uncomment to use animateCSS
// ------------------------------------------
/*.ani-hide {visibility: hidden;}
.animated {visibility: visible;}

.ani-slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.ani-delay-x1 {    
  -webkit-animation-delay: 0.3s; 
  animation-delay:0.3s;
}


.ani-delay-x2 {    
  -webkit-animation-delay: 0.6s; 
  animation-delay:0.6s;
}

.ani-delay-x3 {    
  -webkit-animation-delay: 0.9s; 
  animation-delay: 0.9s;
}

.ani-delay-x4 {    
  -webkit-animation-delay: 1.2s; 
  animation-delay:1.2s;
}

.ani-delay-x5 {    
  -webkit-animation-delay: 1.5s; 
  animation-delay: 1.5s; 
}

.ani-delay-x6 {    
  -webkit-animation-delay: 1.8s; 
  animation-delay:1.8s; 
}

.ani-delay-x7 {    
  -webkit-animation-delay: 2.1s; 
  animation-delay:2.1s; 
}

.ani-delay-x8 {    
  -webkit-animation-delay: 2.4s; 
  animation-delay:2.4s; 
}

.ani-delay-x9 {    
  -webkit-animation-delay: 2.7s; 
  animation-delay: 2.7s; 
}

.ani-delay-x10 {    
  -webkit-animation-delay: 3s; 
  animation-delay: 3s;
}
*/


.center-align-vert { 
  display: -webkit-flexbox; 
  display: -ms-flexbox; 
  display: -webkit-flex; 
  display: flex; 
  -ms-flex-direction: column; 
  flex-direction: column; 
  -webkit-flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center; 
  -ms-justify-content: center;
  -webkit-justify-content: center;
}
.center-align-vert-v2 { 
  display: -webkit-flexbox; 
  display: -ms-flexbox; 
  display: -webkit-flex; 
  display: flex; 
  -webkit-flex-align: center; 
  -ms-flex-align: center; 
  -webkit-align-items: center; 
  align-items: center;
}
.center-align{
  display: -webkit-flexbox; 
  display: -ms-flexbox; 
  display: -webkit-flex; 
  display: flex; 
  -webkit-flex-align: center; 
  -ms-flex-align: center; 
  -webkit-align-items: center; 
  align-items: center; 
  justify-content: center; 
  -ms-justify-content: center;
  -webkit-justify-content: center;
}


// BACKGROUNDS
// ------------------------------------------
.blue-bg { background: $brand-blue; color:white;}
.lighter-bg { background: $lighter-grey;}
.light-grey-bg {background:$light-grey;}



// CTA
// ------------------------------------------
.cta-primary {@include solid-btn($brand-blue, white, white); }
.cta-primary-border {@include solid-btn-border($brand-blue, $brand-blue, $brand-blue);}

.cta-black {
  margin-right:20px;
  @include solid-btn-border($brand-text, $brand-text, $brand-text);
  &:hover, &:focus, &:active  {
    background:white !important;
  }
}

.cta-white {@include solid-btn(white, $brand-blue, $brand-blue);}
.cta-red {@include solid-btn($brand-red, white, white);}
.cta-red-border {@include solid-btn-border($brand-red, $brand-red, $brand-red);}

.cta-blue {@include solid-btn($brand-blue, white, white);}
.cta-blue-border {@include solid-btn-border($brand-blue, $brand-blue, $brand-blue);}

.cta-dark-blue {@include solid-btn($brand-dark-blue, white, white);}
.cta-dark-blue-border {@include solid-btn-border($brand-dark-blue, $brand-dark-blue, $brand-dark-blue);}

.cta-orange {@include solid-btn($brand-orange, white, white); }
.cta-orange-border {@include solid-btn-border($brand-orange, $brand-orange, $brand-orange);}

.cta-grey {
  @include solid-btn($light-grey, $brand-text, $light-grey);
  font-size:14px; margin:0; padding:5px 20px; box-shadow: none;
  &:hover, &:focus, &:active  {
    color:$brand-text; transform: none; box-shadow: none;
  }
}

.cta-play-video {
	img {
		margin: -5px 5px 0 0;
	}
}

.play-video-button-container {
	height: 447px;
	position: relative;
}

.play-video-button {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0.7;
	background: none;
	
	&:hover, &:focus {
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		opacity: 1;
		background: none !important;
		margin-top: -1px;
		box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
	}
	
	img {
    	width: 170px;
		height: 170px;
	}
	
	span {
    	display: none;
	}
}

.bottom-page-cta {@include floatW; box-shadow: 0 4px 6px rgba(55,55,55,.3); padding:40px;}



// HEADER
// ------------------------------------------
header { 
	@include floatW; 
	padding:35px 0; 
	background-color:white;
	//box-shadow: 0 3px 6px rgba(0,0,0,.2); 
	z-index: 2; 
		
	.menu-main-menu-container, 
	.menu-frontend-menu-logged-in-container, 
	.menu-backend-menu-logged-in-container, 
	.menu-right-menu-container, 
	.menu-frontend-right-menu-logged-in-container, 
	.menu-backend-right-menu-logged-in-container { 
		width: auto; 
		margin-top: 10px;
		
		ul {  
			margin: 0px; 
			padding: 0; 
			list-style: none; 
			float: right;
	    	
	    	li { 
		    	display: inline-block; 
		    	text-align: right; 
		    	text-decoration: none; 
		    	font-size: 16px; 
		    	color: #4C697B; 
		    	list-style: none; 
		    	font-weight: 500; 
		    	margin-left: 41px;
		    	
		    	&:first-child{
			    	margin-left: 0px;
		    	}
				
				a { 
					color: $brand-text;
					text-transform: uppercase;
				}
	    	}
		}
		
		#menu-main-menu .current-menu-item a,
		#menu-main-menu .current-page-item a,
		#menu-frontend-menu-logged-in .current-menu-item a,
		#menu-frontend-menu-logged-in .current-page-item a, {
		    color: white;
		    background: #132C37;
		    padding: 4px 11px;
		    border-radius: 6px;
		}
	}
	
	button {
		background:transparent; 
		box-shadow:initial;
		
		&:hover ,
		&:focus,
		&:active,
		&:visited {
			background:transparent; 
			box-shadow:initial;
		}
	}
}

/*
body.home {
  header {position: absolute; top:0; z-index:20 ; padding:35px 0; background:transparent;  box-shadow:none;
    ul li a {color:white;}
  }
}
*/

.menu-main-menu-container, 
.menu-frontend-menu-logged-in-container, 
.menu-backend-menu-logged-in-container {
	float:left;
}

.menu-right-menu-container, 
.menu-frontend-right-menu-logged-in-container, 
.menu-backend-right-menu-logged-in-container {
	float:right;
}


// SIDEBAR
// ------------------------------------------



// SLIDER
// ------------------------------------------
.slider-for { margin-bottom:20px;}
.slider-nav {  margin-bottom:20px;
  .slick-track {margin:0;
    .slick-slide {margin-right:20px; 
      &:focus {outline:none;}
    }
  }
}
.slick-slide {height:auto !important;}



// MODAL OVERRIDES
// ------------------------------------------
#myModalLabel {margin:0;}
.modal-header, .modal-body {padding:20px 40px;}
.modal-dialog {width:450px;}
.modal-lg {width:950px;}
.modal {width:100% !important; max-height: 100%!important;}




// GLOBAL USAGE
// ------------------------------------------
.content-section { @include floatW; padding:80px 0 41px; overflow: hidden; position: relative; }
.float { @include floatW; overflow: hidden; position: relative; }
.content-text-lg { font-size:21px; }
.content-text-md { font-size:18px; }
.content-text-sm { font-size:16px; }

.floatW { @include floatW; }
.blobs { position: absolute; }

.mt-150 { margin:150px 0 60px 0 }
.p-180 { padding:180px 0; }

.text-center { margin-bottom:30px; }

.container-fluid { padding: 0 60px; }



// HOME
// ------------------------------------------
/* Landing page */
.vimeo-wrapper {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
   pointer-events: none;
   overflow: hidden;
}
.vimeo-wrapper iframe {
   width: 100vw;
   height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
   min-height: 100vh;
   min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}


.landing-page-lg {
	background-color: #65D1FF;
    position: relative;
	@include floatW; 
	@include landing-image-grad(#456D7E, 0, #456D7E, 0);
	overflow: hidden;
	
	#landing-page-banner-image {
		background-repeat: no-repeat,repeat;
		background-position: center;
		background-size: contain;
		height: 567px;
		width: 50%;
		position: absolute;
		z-index: -1;
	}
	
}

.page-template-template-home, .page-template-template-about, .page-template-template-product {
	.landing-page-lg {
		background-color: #132C37;
		background-image: url('../images/banner-background.svg');
		background-repeat: no-repeat;
		background-position: 122% 50%;
		background-size: 687px;
		@include landing-image-grad(#456D7E, 0, #456D7E, 0);
	}
}

.page-template-template-home {
	.landing-page-lg {
		background-position: -178px;
	    background-size: 687px;
	}
}

.page-template-template-home {
	.landing-page-lg {
		.landing-page-content {
			padding: 72px 0 87px 0;
			
			#landing-page-banner-logo {
				width: 100%;
				padding: 0 0 31px 7%;
			}
			
			h1 {
				padding: 0 0 52px 14.1%;
				width: 100%;
			}
			
			.cta-container {
				padding: 0 0 0 14.1%;
				width: 100%;
			}
			
			.cta-orange {
				margin: 0;
			}
			
			#landing-page-banner-image {
				width: 327.62px;
				float: left;
				position: relative;
				max-height: 403px;
				
				img {
					position: absolute;
					top: 64px;
				}
			}
		}
	}
	
	.modal-dialog {
    	width: 80%;
    	
    	.modal-content {
	    	box-shadow: 0 0 0 rgba(0,0,0,0);
	    	background: none;
			border: 0px solid rgba(0,0,0,0);
			border-radius: 0px;
			box-shadow: 0 0 0 rgba(0,0,0,0);
    	}
    	
    	.modal-body, .modal-header {
	    	padding: 0px;
    	}
    	
    	.modal-header {
	    	padding: 0px;
	    	border-bottom: 0px solid #000000;
	    	
	    	.close {
		    	color: white;
		    	opacity: 1;
	    	}
    	}
    	
    	.modal-body {
	    	padding:0;
    	}
	}
	
	#clientTestimonialVideoModal iframe {
    	margin: 14px 0 50px;
	}
}

// Games
// ------------------------------------------
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.page-template-template-demos {
	.landing-page-lg {
		
		.landing-page-content h1,
		.landing-page-content h2 {
			width: auto;
		}
		
		.landing-page-content {
			padding: 51px 0 242px;
			
			#landing-page-banner-image {
			    width: 100%;
			    max-height: 484px;
			    float: left;
			    height: auto;
			    bottom: 24px;
			    
			    img {
				    max-width: 124%;
			    }
			}
		}
		
		#landing-page-banner-sub-background-color {
			float: left;
			width: 100%;
			height: 150px;
			background: #2B7FA3;
			position: absolute;
			bottom: 0px;
			z-index: -2;
		}
	}
	
	.content-section.demos-list {
    	text-align: center;
    	padding: 36px 0 41px;
    	
    	h2 {
	    	color: #3D88B7;
	    	font-size: 36px;
    	}
    	
    	p {
	    	font-size: 24px;
	    	color: #575757;
	    	padding-bottom: 50px;
    	}
    	
    	.game-card {
	    	margin-bottom: 45px;
	    	
	    	.game-info {
		    	height: 180px;
		    	
		    	h4 {
			    	font-size: 28px;
			    	font-weight: 700;
			    	color: #4C697B;
		    	}
		    	
		    	p {
			    	font-size: 24px;
			    	color: #4C697B;
			    	padding-bottom: 0px;
		    	}	
	    	}
    	}
	}
}


// BREADCRUMB
// ------------------------------------------
.breadcrumbs {
	float: left;
	width: 100%;
	background: #65b9db;
	color: #fff;
	padding: 10px 0;
	
	a {
		color: #fff;
		font-weight: 600;
		&:hover {
			color: #fff;
		}
	}
}


// About
// ------------------------------------------
.page-template-template-about {
	.landing-page-lg {
		#landing-page-banner-image {
			z-index: 0;
		}
	}

	.landing-page-content {
		min-height: 567px;
	}
	
	.cta-container {
		float: left;
		width: 100%;
		text-align: center;
	}
}

.news-card-model {
    margin-top: calc(10vh);
    color: white;
}

// Blog
// ------------------------------------------
.blog {
	.content-section {
		background : white;
		
		h1 {
			color: #4c697b;
		}
		
		.navigation {
			width: 100%;
			float: left;
			
			a {
				color: #65b9db;
			}
			
			.alignleft {
				float: left;
			}
			
			.alignright {
				float: right;
			}
		}
	}
}




.landing-page-content { 
	position: relative;
	padding: 51px 0 111px;
    
    h1, h2 {
	    margin: 0px; 
	    font-size: 28px;
	    font-weight: 500;
	}
    
    #landing-page-banner-logo {
		width: 100%;
    }
    
    h2 {
	    margin: 0; 
	    font-size: 28px; 
	    font-family: $font-stack1; 
	    margin: 20px 0;
	}
}

.home-page-product-explained {
	float: left;
    width: 100%;
    padding: 43px 0 92px;
	
	.home-page-video-container {
		
		h2 {
		    text-align: center;
		    color: #235876;
		    font-size: 36px;
		    font-weight: 500;
		    margin: 0;
		}
		
		.embed-container {
			position: relative;
			padding-bottom: 56.25%;
			height: 0;
			overflow: hidden;
			max-width: 100%;
			margin: 0 0 50px;
		}
		
		.embed-container iframe,
		.embed-container object,
		.embed-container embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		iframe {
		    margin: 42px 0 50px;
		}
		
		.video {
			position: relative;
			
			img {
				width: 100%;
				max-height: 599px;
				margin: 42px 0 50px;
			}
			
			.play {
			    position: absolute;
				z-index: 1;
				top: calc(50% - 131px);
				left: calc(50% - 85px);
				cursor: pointer;
			    
			    img {
				    width: 170px;
				    height: 170px;
				    opacity: 0.7;
			    }
			    
			    span {
					display: none;
				}
			}
		}
	}
	
	.home-page-product-breakdown {
		text-align: center;
		color: #132C37;
		
		h2 {
		    font-size: 36px;
		    font-weight: 500;
		    margin: 0;
		}
		
		.home-page-product-breakdown-step {
			margin: 62px 0 73px;
			
			.home-page-product-breakdown-step-image {
				margin-bottom: 13px;
				float: left;
				width: 100%;
				height: 72px;
				position: relative;
				
				img {
					margin: 0;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    -ms-transform: translate(-50%, -50%);
				    transform: translate(-50%, -50%);
				}
			}
			
			h3 {
				margin: 0;
				font-size: 24px;
			}
		}
		
		.cta-blue {
			margin: 0;
			background-color: #009DDD;
		}
	}
}

.home-page-client-testimonial {
	height: 571px;
	background-image: url('../images/chief-atahm-still.jpg');
	background-position: -204px -420px;
	position: relative;
	padding: 41px 0 41px;
	
	#text-backgound{
		position: absolute;
		background: rgba(255,255,255,0.8);
		width: 41.65%;
		height: 100%;
		top: 0;
		right: 0;
	}
	
	h2 {
		margin: 0px;
		font-size: 24px;
		font-weight: 700;
		color: #009DDD;
	}
	
	h3 {
		margin: 0px 0px 38px;
		font-size: 36px;
		font-weight: 500;
		color: #243B44;
	}
	
	p {
		font-size: 24px;
		margin-bottom: 54px;
		color: #243B44;
	}
	
	.cta-dark-blue {
		background-color: #009DDD;
	}
}

.home-page-associated {
	text-align: center;
	padding: 83px 0;
	
	h2 {
		font-size: 21px;
		font-weight: 600;
		color: #132C37;
	}
	
	ul {
		margin: 36px 0 0 0;
		list-style-type:none;
		
		li {
			float:left;
			margin: 0px;
		}
		
		#home-page-associated-1 {
			width: 22.5%;
			margin: 18px 1.5% 0 0;
		}
		
		#home-page-associated-2 {
			width: 34.1%;
			margin: 11px 3.8% 0 0;
		}
		
		#home-page-associated-3 {
			width: 20.6%;
			margin: 0 2.6% 0 0;
		}
		
		#home-page-associated-4 {
			width: 14.4%;
			margin: 32px 0 0 0;
		}
	}
}

#clientTestimonialVideoModal {
	.embed-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		margin: 0 0 50px;
	}
	
	.embed-container iframe,
	.embed-container object,
	.embed-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	iframe {
	    margin: 42px 0 50px;
	}
}

.home-page-latest-news {
	background: #65B9DB;
	padding: 35px 0 87px 0;
	
	h3 {
		font-size: 36px;
		font-weight: 700;
		color: white;
	}
}

.page-template-template-about {
	.home-page-latest-news {	
		background: #65B9DB;
		
		h3 {
			color: white;
			margin-bottom: 40px;
		}
	}	
}

.newsletter-sign-up {
	background: #235876;
	color: white;
	padding: 31px 0 54px;
	text-align: center;
	
	h3 {
		font-size: 36px;
		font-weight: 500;
	}
	
	p {
		font-size: 21px;
	}
	
	input[type=text], input[type=email], textarea {
		font-size: 24px;
		color: rgba(61,136,183,0.5);
		padding: 13px;
		border: 0px solid #4C697B;
		border-radius: 8px;
		
		&::placeholder {
		  color: rgba(61,136,183,0.5);
		  opacity: 1;
		}
	}
	
	.cta-dark-blue {
		background: #009DDD;
		margin-top: 35px;
		
		&:hover {
			background: lighten(#009DDD, 5%) !important; 
		}
		
		&:focus {
			background: lighten(#009DDD, 10%); 
		}
	}
}

.key-feature {
  i {font-size:52px; margin-bottom:15px;}
  p {padding-right:25px;}
}

.first-section-block {
  .teal-blob1 {top:0; right:0; max-width:700px; width:100%;}
  .teal-blob2 {bottom:0; left:0; max-width:300px; width:100%}
  .teal-blob3 {bottom:0; right:0; max-width:700px; width:100%}

  .jetman-container { position: absolute; bottom:0; height:320px; width:400px;
    .blue-clouds {max-width:430px; width:100%; position: absolute; bottom: 0;}
    .jetman {max-width:160px; width:100%; position: absolute; right: 108px; bottom: 185px; animation: MoveUpDown 3s linear infinite;}
  }

}

.second-section-block {
  .blue-blob1 {top:0; right:0; max-width:735px; width:100%}
  .blue-blob2 {bottom:0; left:0; max-width:500px; width:100%}
}

.third-section-block {
  .teal-blob4 {top:0; right:0; max-width:240px; width:100%}
  .teal-blob5 {bottom:0; left:0; max-width:640px; width:100%}
}

.fourth-section-block { text-align: center; 
  blockquote {font-size:32px; color:white; border:none; padding:0; margin:0;}
  cite {font-size:24px; color:white; display:block; margin-top:20px; font-weight:700;}
  .blue-blob3 {bottom:0; left:0; width:100%; width:100%}
}

.fifth-section-block {
  .teal-blob2 { transform: rotate(90deg); max-width: 460px; left: 0; top: 0; z-index: -1; width:100%}
  .teal-blob6 { bottom:0; right:0; max-width:800px; width:100% }
}



/* Coloured section break */
.primary-colour-section {
  @include coloured-section($brand-blue);
}



// SUBPAGES
// ------------------------------------------
.page-layout {position: relative;
  .teal-blob1 {top:0; right:0; max-width:550px; width:100%}
  .teal-blob2 {bottom:0; left:0; max-width:400px; width:100%}
  h3 { margin:25px 0 10px 0;}
}

.about-first-section-block, .demos-list {
  .blue-blob1 {top:0; right:0; max-width:735px; width:100%}
  .blue-blob2 {bottom:0; left:0; max-width:690px; width:100%}
}

.landing-subpage-lg {padding-top:100px; padding-bottom:100px; position: relative;
  p {font-size:21px;}
  a {@include solid-btn(white, $brand-blue, $brand-blue); float:left; margin-right:25px;}
  .jetman-container { position: absolute; bottom:0; height:320px; width:400px; right:0;
    .blue-clouds {max-width:390px; width:100%; position: absolute; bottom: 0;}
    .jetman {max-width:135px; width:100%; position: absolute; right: 126px; bottom: 145px; animation: MoveUpDown 3s linear infinite;}
  }
}

.card { float:left; width:46%; margin:2% 0; text-align: left; border-radius:10px; box-shadow: 0 4px 8px rgba(55,55,55,.3); position: relative;  z-index: 2; background: white;  padding:30px;
  p {font-size:16px;}
  i {font-size:42px; margin-bottom:10px; color:$brand-blue;}
  h4 {margin-bottom:5px; color:$brand-blue;}
}

.what-is-jp { position: relative;
  .teal-blob1 {top:0; right:0; max-width:850px; width:100%}
  .teal-blob2 {bottom:0; left:0; max-width:400px; width:100%}

  .col-md-6 {
    .card:nth-child(1) {margin-top:60px;}
    .card:nth-child(3) {clear:both;}
    .card:nth-child(4) {margin-top:-40px;}
    .card:nth-child(odd) {margin-right:2%;}
    .card:nth-child(even) {margin-left:2%;}
  }
  a {@include solid-btn($brand-blue, white, white); float:left;}
}

.services-we-offer {position: relative;
  .blue-blob1 {position: absolute; top:0; right: 0; width:80%;}
  .col-sm-6{
    &:nth-child(2n+2) {clear:both;}
  }
}

.services-card { @include card; min-height:250px; margin:15px 0; padding:40px;
&:hover { transform: translateY(0px); }
  .services-img {width:20%; float:left;
    img { width:64px; height:64px; margin-top:20px;}
  }
  .services-info { color:$brand-text; width:80%; float:left;
    p {margin-bottom:0;}
  }
} 

.large-intro-text {font-size:38px; text-align:center; color:white; position: relative;
  p {margin:0;}
  .blue-blob3 {bottom:0; left:0; width:100%;}
} 

/* left half, right half restricted to 12 grid */
.left-half-container {@include floatW; position: relative;}
.left-half-img {width:45%; float:left; background: url('') center center no-repeat; background-size: cover; height:615px; }
.right-half-content {@include floatW; position: absolute; top: 0; height:615px;
  a {@include solid-btn-border($brand-blue, $brand-blue, $brand-blue); float:left; margin-right:25px;}
}




// GAME DEMOS
// ------------------------------------------
.game-card { @include card; 
  .game-img {
    img {border-top-left-radius: 10px; border-top-right-radius: 10px;}
  }
  .game-info {padding:20px 40px; color:$brand-text; 
    h4 {margin-bottom:10px; color:$brand-blue;}
  }
} 


.additional-container {@include floatW;}
.additional-title {background:$brand-blue; padding:20px 40px; 
  h4 {margin:0; color:white;}
}
.additional-info {@include floatW; background:white; padding: 40px; min-height:480px;
  h5 { font-size:18px;
    &:nth-of-type(2){ margin-top:50px; }
  }

  ul { margin:0; padding:0;
    li {list-style:none; margin:15px 0; font-size:16px;
      &:before { 
      content:''; 
      display:inline-block; 
      height:20px; 
      width:20px; 
      margin:-4px 0;
      background-image:url('../images/check.svg'); 
      background-size:contain; 
      background-repeat:no-repeat; 
      padding-left: 35px;
      }
    }
  }
}

.sample-demo {
	display:none; 
	@include floatW;
	position: absolute; 

  .demo-info-panel { @include floatW; background:white; box-shadow: 0 3px 6px rgba(0,0,0,.2); position: relative; z-index:2; height: 50px;
    a.back-to-live-site { padding-left: 35px; }
    .logo { float:left; padding-left:35px; }
    .demo-info { margin:0 auto; text-align: center;
      h4 { font-size:19px; font-weight:500; margin:0 10px 0 0;}
      a { font-size:14px;}
    }
    .demo-cta {float:right; background: $brand-blue; color:white; height:100%;
      &:hover {text-decoration:none; background:darken($brand-blue,15%);}
    }
    .left-cta {float:left; background: $light-grey;}
    .right-cta { font-weight:500; color:white; padding:0 30px;}  
  }

  .demo-iframe {@include floatW; /*position:relative;*/ height: calc(100vh - 50px);
     iframe {/*position:absolute; top:0; left:0;*/ width:100%; height:100%;}
  }
}





// TEMPLATES ARCHIVE
// ------------------------------------------
body.page-template-template-templates-page {
  .container { width:100% !important;
    .row { margin:0 -15px !important;}
  }
}


.no-results {display:none; float:left; width:100%;}
.grid {float:left; width:100%; margin-top:40px;}

.template-card-container { 
  &:nth-child(6n+1) {clear:both;}
}


.template-card { float:left; width:100%; margin-bottom:30px;   box-shadow: 0 2px 4px rgba(0,0,0,.30); border-radius:5px;
  &:hover {
    text-decoration: none;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 4px 8px rgba(0,0,0,.08);
    border-bottom-left-radius:5px; border-bottom-right-radius:5px; 
  }
  .card-img { img {border-top-left-radius:5px; border-top-right-radius:5px;} }
  .card-info { float:left; width:100%; padding:15px 20px; border-bottom-left-radius:5px; border-bottom-right-radius:5px; color:$brand-text; background:white;
    h4 {font-size:16px;  margin:0 0 3px 0;}
    a {color:$brand-text; font-weight:700; display:block;}
    p {margin-bottom:0;}
    .game-type {font-size:13px;}
  }
}


// Select material design
.input-field {margin-top:30px;}
.select-wrapper+label {font-size:11px;}
.dropdown-content li {min-height:40px; margin-left:0 !important;}
.dropdown-content li>a, .dropdown-content li>span {color:$brand-text; padding:10px 16px; font-size:15px;}
.select-wrapper input.select-dropdown {height:50px; font-size:14px;}
.select-wrapper span.caret {border:none; right:15px; color:#ddd;}
.select-wrapper input[type=text] {
  border:none !important;
  border-bottom:3px solid #ddd !important;
}


body.single-templates {
  .cta-primary, .cta-primary-border {margin-right:15px;}
}


// MY GAMES SECTION
// ------------------------------------------

.my-games-section {position: relative; min-height:500px;
    .intro {display:none; font-size:19px;}
    .initial-loading {box-shadow: initial; background:transparent;}
}


.authorisedUser { float: left; width: 100%;}

.counterQuestion {
  input { width:95%; }
  label { float:left; width:5%; margin: 0;}
}



// Client Game Panels
// ------------------------------------------
.action-panels {@include floatW; background:$light-grey; border-radius:10px; box-shadow: 0 2px 4px rgba(0,0,0,.30); padding:30px; margin: 20px 0;
} 
.top-layer {position: relative; z-index:2;}

.single-game-section { position: relative;
  .teal-blob1 {top:0; left:0; transform: rotate(90deg); max-width: 460px; width:100%}
  .teal-blob2 {bottom:0; right:0; max-width:500px; width:100%}
}


body.page-template-template-mygames {
  .card-img { position: relative; 
    &:hover {.card-overlay {opacity:1;} cursor:pointer}
    
  }
  .card-info {
    p { font-size:14px; font-weight:700; span {font-weight:400;} }
  }
  .card-overlay {opacity:0;  transition: opacity .4s linear; position: absolute; top:0; left:0; color:white; text-align:center; font-weight:700; background:rgba($brand-primary, 0.9); width:100%; height:100%; border-top-right-radius:5px; border-top-left-radius:5px;}
}

.col-lg, .col-sm, .col-xs {float:left; padding: 0 10px;}
.col-lg {width:45%;}
.col-sm {width:16.66666%;}
.col-xs {width:5%;}

label {font-size:12px; margin-bottom:10px;}

.authorisedUser { @include floatW;}

.counterQuestion {
  input { width:95%; }
  label { float:left; width:5%; margin: 0;}
}

.how-to-edit, .content-to-edit, .initial-loading {
  @include floatW;
  padding: 40px; box-shadow:0 3px 6px rgba(0,0,0,.2);
  background: #fff;
  margin-bottom:30px;
}

.intro-add-question, .how-to-edit {
  p {font-size:19px;}
  h3 {margin-bottom:10px;}
}

.backToContent, .content-to-edit {display:none;}
.processing-content, .complete-content {display:none; text-align: center;
  p {font-size:21px;}
}
.initial-loading {text-align: center;
  p {font-size:21px;}
}

.loading-content, .processing-content { font-weight:700;}
.complete-content {color:$brand-success; font-weight:700;}

.my-games-section {
    .intro {display:none; font-size:19px;}
    .initial-loading {box-shadow: initial; background:transparent;}
}

.alert-panel {@include floatW; padding:30px; color:white; background:$brand-error; font-size:19px; margin-top:15px; text-align: center; display:none;
  p {margin:0;}
}


.removeQuestion { @extend .cta-grey; }



// NEWS
// ------------------------------------------
.news-card { 
	@include card;
	margin-bottom: 30px;
	
	.news-img {
		height: 269px;
		
		img {
			border-top-left-radius: 10px; 
			border-top-right-radius: 10px;
		}
	}
  
	.news-info {
		padding: 17px 40px 40px 40px; 
		color: $brand-text; 
		height: 338px;
		
		h4 {
			font-size: 24px;
			font-weight: 700;
			color: #4C697B;
		}
		
		p {
			font-size: 21px;
			font-weight: 500;
		}
		
		span {
		    color: #3D88B7;
		    font-family: semplicitapro,sans-serif;
		    font-weight: 500;
		    border-bottom: 0px solid #4c5ed3;
		    padding-bottom: 5px;
		    text-transform: capitalize;
		    font-size: 18px;
		    text-decoration: none;
		}
	}
}

.page-template-template-about {
	.landing-page-lg {
		.mainimg.center-align {
			.landing-page-content {
				position: relative;
				
				h1 {
					font-weight: 700;
				}
				
				h1, h2, .cta-container {
					padding: 0 0 0 70px;
					text-align: left;
				}
				
				#landing-page-banner-image {
					width: 85%;
				    height: auto;
					bottom: 33px;
					right: -26%;
				}
			}
		}
	}
	
	.about-page-founders {
		background: #65B9DB;
		padding-top: 48px;
		
		h3 {
			color: white;
			font-size: 36px;
		}
		
		.news-card {
			.news-img {
				height: auto;
			}
			
			.news-info {
				padding: 17px 40px;
				height: auto;
				
				h4 {
					text-align: center;
					margin: 0;
					font-weight: 500;
				}
			}
		}
	}
	
	.about-page-advisors {
		background: white;
		padding-top: 48px;
		
		h3 {
			color: #4C697B;
			font-size: 36px;
		}
		
		.news-card {
			.news-img {
				height: auto;
			}
			
			.news-info {
				padding: 17px 40px;
				height: auto;
				
				h4 {
					text-align: center;
					margin: 0;
					font-weight: 500;
				}
			}
		}
	}
}

.page-template-template-product {
	
	.landing-page-lg {
		.landing-page-content {   
		    padding: 26px 0 15px;
			
			#landing-page-banner-image {
				position: relative;	
				width: 100%;
				height: auto;
				max-width: 1310.41px;
			}
		}
	}
	
	.home-page-product-explained {
		padding-bottom: 0;
	}
	
	.product-page-global-competencies {
		padding-top: 0;
		@include floatW;
		text-align: center;
		
		h2 {
			font-size: 36px;
			padding-bottom: 24px;
		}
		
		p {
			font-size: 20px;
			margin-bottom: 42px;
		}
		
		.about-page-global-competencies-item {
			padding-bottom: 64px;
			
			img {
				margin-bottom: 27px;
			}
			
			h3 {
				font-size: 24px;
				margin:0;
			}
			
			p {
				font-size: 18px;
				margin-bottom: 0;
			}
		}
	}
}

body.single-post {
  .content-section {
    h3, h4 {margin-top:30px;}
  }
}

.blog-article {
  .teal-blob1 {top:0; right:0; max-width:400px; width:100%}
  .teal-blob2 {bottom:0; left:0; max-width:160px; width:100%}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1199px) {
	.news-card {
		.news-img {
			height:220px;
		}
		.news-info {
			height:368px;
		}
	}
}

/* Small Devices, Desktops */
@media only screen and (max-width : 991px) {	
	.news-card {
		.news-img {
			height:165px;
		}
		.news-info {
			height:492px;
		}
	}
}

/* Extra Small Devices, Tablets */
@media only screen and (max-width : 767px) {
	.news-card {
		.news-img {
			height:auto;
		}
		.news-info {
			height:auto;
		}
	}
}



// PRICING
// ------------------------------------------
.pricing{ position:relative; background:$brand-blue; color:white;
  .blue-blob1 {top:0; right:0; max-width:850px; width:100%}
  .blue-blob2 {bottom:0; left:0; max-width:400px; width:100%}
} 

.pricing-card { @include card; @include floatW; padding:0; 
  .pricing-info { color:$brand-text; text-align: center; line-height: 1.2; padding: 40px;
    h3 {font-size:24px; margin-bottom:5px;}
    h4 {font-size:32px; font-weight:700; margin-bottom:0;}
    h5 {font-size:21px; margin-bottom:25px;}
    p {font-size:16px; line-height:1.4; margin:0;}
    span {font-size:14px; font-weight:400; text-transform: lowercase; color:darken($dark-grey, 15%); border:none; margin-bottom:25px; display:block;}
  }
  .pricing-cta {@include floatW; background:$brand-red; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; text-align: center; padding:20px 0;
    span {border: none; color:white;}
  }
} 



// LOGIN REGISTER
// ------------------------------------------
.page-template-template-login, .page-template-template-sign-up {
	.login-reg { 
		@include floatW; 
		position:relative;
		background-color: #65B9DB;
		color: white;
		padding: 51px 0;
		
		#loginForm {
		    float: left;
			width: 95%;
			border-right: 1px solid white;
		}
		
		h2 {
			font-size: 28px;
			margin-bottom: 32px;
		}
		
		.um-field {
		    padding: 0;
		}
		
		input[type=text], input[type=email], input[type=password], textarea {
			font-size: 24px !important;
			color: rgba(61,136,183,0.5) !important;
			padding: 13px !important;
			border: 1px solid #4C697B !important;
			border-radius: 8px !important;
			margin-bottom: 28px !important; 
			height: auto !important;
			
			&::placeholder {
			  color: rgba(61,136,183,0.5) !important;
			  opacity: 1 !important;
			}
		}
		
		input#um-submit-btn {
			background: #334F60 !important;
			text-transform: capitalize!important;
			padding: 13px 24px!important;
			
			&:hover, &:active, &:focus {
				background: #334F60 !important;
			}
		}
		
		#loginForm {
			.um-field-area {
				width: 450px;
			}
		}
		
		a.um-link-alt {
			color: white !important;	
		}	
		
		#registerAccount {
			@include floatW; 
			margin-top: 10px;
			
			h2 {
				margin: 0px;
			}
			
			.cta-dark-blue {
				margin-bottom: 17px;
			}
		}
		
		#registerForm {
			.um-register {
				.um-postmessage {
					color: white;
				}
			}
		}
		
		.um-field-checkbox-option, .um-field-radio-option {
		    color: #fff !important;
		    font-size: 19px !important;
		}
		
	}
}


// CONTACT US
// ------------------------------------------
.page-template-template-contact {
	.landing-page-lg {
		#landing-page-banner-image {
			z-index: 0;
			height: 430px;
			bottom: 0;
		}
		
		.landing-page-content { 
			h1 {
				width:100%;
				margin-bottom: 59px;
			}
			
			p {
				font-size: 28px;
				margin: 0 0 32px 0;
			}
			
			a#email {
				font-weight: 700;
				color: white;
			}
			
			#follow-us {
				p {
					float: left;
					margin:0px;	
				}
				
				ul.footerSocialMedia {
				    margin: 0;
				    padding: 0;
				    list-style: none;
				    float: left;
				    
				    li {
					    float: left;
				    }
				}
			}
		}
	}
	
	.contact { 
		position: relative;
		text-align: center;
		padding-top: 25px;
		
		h3 {
			font-size: 36px;
			margin-bottom: 27px;
		}
		
		p {
			font-size: 21px;
		}
		
		.contact-form {
			width:100%;
			float: left;
			
			form {
				width:100%;
				float: left;
				
				input[type=text], input[type=email], textarea {
					font-size: 24px;
					color: rgba(61,136,183,0.5);
					padding: 13px;
					border: 1px solid #4C697B;
					border-radius: 8px;
					margin-bottom: 20px; 
					
					&::placeholder {
					  color: rgba(61,136,183,0.5);
					  opacity: 1;
					}
				}
				
				input[type=submit] {
					background: #334F60;
					
					&:hover, &:active, &:focus {
						background: #334F60 !important;
					}
				}
			}
		}
	}
}






// 404 ERROR AND SEARCH
// ------------------------------------------
body.error404, body.search, body.search-results {
  .page-layout {position: relative;
    .blue-blob1 {top:0; right:0; max-width:450px; width:100%}
    .blue-blob2 {bottom:0; left:0; max-width:350px; width:100%}
  } 
  button {margin-top:20px;}
}



// FORMS
// ------------------------------------------
label {font-weight:600; color:$brand-text; width:100%;}

input[type="text"], input[type="email"], input[type="tel"], input[type="search"], textarea { 
  font-weight:400; color:$brand-text; padding: 10px; margin:0; border-radius:5px; border:1px solid $dark-grey; width:100%;
}

select {width:100%;}

.wpcf7-response-output {@include floatW;}
.wpcf7 input[type="tel"],
.wpcf7 input[type="text"],
.wpcf7 input[type="email"] {
  margin-top:8px; 
  &::-webkit-input-placeholder {color:lighten($brand-text,55%);}
  &:-moz-placeholder {color:lighten($brand-text,55%);}
  &::-moz-placeholder {color:lighten($brand-text,55%);}
  &:-ms-input-placeholder {color:lighten($brand-text,55%);}
}
.wpcf7 input[type="tel"],
.wpcf7 input[type="text"],
.wpcf7 input[type="email"],
textarea {margin-top:8px;}
button, input[type="button"], input[type="submit"] {
  @extend .cta-primary; margin:0; border:none; padding: 8px 30px;
}




// Ultimate Member Plugin Override
// ------------------------------------------
.um { opacity: 1 !important; }
.um-register, .um-login {float:left;}
.um-center, .um-link-alt {text-align: left !important;}
#um-submit-btn { @include solid-btn($brand-primary, white, white); font-weight:600!important; margin:0; border-radius:50px !important; padding:10px 20px !important; text-transform: uppercase !important; min-width: initial !important;}
.um-um_password_id.um {margin-left:0 !important; margin-right:0 !important; text-align: left !important;}

body.page-id-116 {
  .um-center {text-align: center !important;}
}

.g-recaptcha { margin: 0 0 30px 0 !important; }
.select-wrapper .select-dropdown  {cursor:pointer !important;}

.um input[type=submit].um-button, 
.um input[type=submit].um-button:focus, 
.um a.um-button, 
.um a.um-button.um-disabled:hover, 
.um a.um-button.um-disabled:focus, 
.um a.um-button.um-disabled:active { @extend .cta-primary; border-radius:50px !important;}

div.uimob800 .um-account-side li a.current, div.uimob800 .um-account-side li a.current:hover { background:$brand-primary; }


// INCLUDES FILES
// ------------------------------------------
.bottom-card-cta { @include card; padding:40px; background:white; float:left;
  p {font-size:24px;}
  .card-content {float:left; width:75%; margin-right:5%;}
  .card-cta {float:left; width:20%;}
  .cta-blue {padding:8px 25px;
    &:hover {color:white;}
    &:focus {color:white;}
    &:hover {color:white;}
  }
} 




// KEY FRAME STUFF
// ------------------------------------------
@keyframes MoveUpDown {
  0%,100%{ -webkit-transform:translate(0, -3px) scale(1.03);}
  50%{ -webkit-transform:translate(0, 3px) scale(1);}
}


// FOOTER
// ------------------------------------------
footer {
	@include floatW;
	margin: 0;
	padding: 38px 0; 
	background-color: #132C37; 
	text-align: center;
	
	.small-print {
		float: left;
		width: 100%;
		padding-top: 40px;
		border-top: 1px solid white;
		
		small {
			color: white; 
			display: block; 
			margin: 5px 0;
			font-weight: 600;
			font-size: 16px;
		}
	}
	
	.footerNav {
		@include floatW;
		padding-bottom: 54px;
		
		ul {
	    	margin: 0px;
	    	
	    	li {
		    	margin-left: 0;
				display: inline;
				margin-right: 29px; 
				
				a {
					font-size: 16px;
					color: white;
					text-transform: uppercase;
					font-weight: 600;
				}
		    }
		}
	}
	
	.footerSocialMedia {
		@include floatW;
		padding-bottom: 71px;
    	margin: 0px;
    	
    	li {
	    	margin-left: 0;
			display: inline;
			margin-right: 25px; 
			
			a {
				
			}
			
			img {
				width: 37px;
			}
	    }
	}
}


@import "common/media-queries.scss";
