// Variables
// --------------------------------------------------

$font-size-base:			14px !default;
$line-height-base:  		1.428571429 !default;
$line-height-computed:		floor(($font-size-base * $line-height-base)) !default;
$screen-sm:          		768px !default;
$screen-sm-min:      		$screen-sm !default;

$brand-text: #4C697B;
$brand-primary: #4C5ED3;

$brand-success: #5ED34C;
$brand-process: #ECAD51;
$brand-error: #EB5469;

$brand-orange:  #F67906;
$brand-red:  #E83C53;
$brand-blue: #3D88B7;
$brand-dark-blue: #334F60;
$brand-light-blue: #65B9DB;

$brand-teal: #44DBC7;
$brand-navy: #495C64;
$dark-grey: #d2d5d6;
$light-grey: #EFF3F4;
$lighter-grey: #F8FDFD;


$font-stack1: semplicitapro, sans-serif;
$font-stack2: semplicitapro, sans-serif;