/* `Basic HTML
----------------------------------------------------------------------------------------------------*/

body {
  font: 18px/1.5 $font-stack1 !important;
  -webkit-font-smoothing: antialiased; /*fixes rendering problems*/
  -moz-osx-font-smoothing: grayscale;
  color:$brand-text;
  font-weight:400;
}

a {
  text-decoration: none;
  color:$brand-blue;
  &:hover {
    color:darken($brand-blue,25%);
    text-decoration: underline;
  }
}


em	{font-style: italic;}

p,
dl,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
pre,
table,
address,
fieldset,
figure {
  margin-bottom: 15px;
}

small{
  font-size:13px;
}

blockquote {
  padding: 11px 22px;
  margin: 0 0 22px;
  font-size: 20px;
  border-left: 5px solid $brand-text;
  color:$brand-text;
}


/* `Headings
----------------------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6	{font-weight:500; font-family: $font-stack2; margin-top:0; line-height: 1.5}
h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}


/* `Spacing
----------------------------------------------------------------------------------------------------*/

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  margin-left: 20px;
}
