// MEDIA QUERIES
// ------------------------------------------

/* Medium Devices, Desktops */
@media only screen and (max-width : 1200px) {
	.left-half-img, .right-half-content { 
		height:415px; 
	}
	
	.services-card { 
		padding:30px;
	}
	
	.landing-page-lg {
		#landing-page-banner-image {
			height: 543px;
		}
	}
	
	header { 
		.menu-main-menu-container,
		.menu-frontend-menu-logged-in-container, 
		.menu-backend-menu-logged-in-container, 
		.menu-right-menu-container, 
		.menu-frontend-right-menu-logged-in-container, 
		.menu-backend-right-menu-logged-in-container { 
			ul { 
				li {  
					margin-left:17px;
				}
			}
		}
	}
	
	.news-card .news-info, .game-card .game-info { 
		padding:25px;
	}
	
	.pricing-card {
		.pricing-info { 
			padding:25px; 
			
			h3 {
				font-size:24px; 
			}
			
			h4 {
				font-size:32px;
			}
			
			h5 {
				font-size:21px;
			}
		}
	}
	
	.container-fluid {
		padding:0 45px;
	}
	
	.template-card-container-hp { 
		&:nth-child(8n-2) {
			clear:both;
		}
		&:nth-child(7n+1) {
			clear:none;
		}
	}
	.col-5th {
		width:25% !important;
	}
	
	.additional-info { 
		min-height:auto; 
		height:auto; 
		padding-bottom:40px;
	}
	
	.removeQuestion {
		font-size:14px; 
		padding:5px 15px;
	}
	
	.content-to-edit, .how-to-edit, .initial-loading {
		padding:25px;
	}
	
	.removeQuestion {
		font-size:11px;
	}
	
	.home-page-client-testimonial {
		p {
			margin-bottom: 20px;
		}
	}
	
	.page-template-template-login-reg {
		.login-reg { 
			#loginForm {
				.um-field-area {
					width: 372px;
				}
			}
		}
	}
	
	.home-page-associated {
		ul {
			#home-page-associated-1 {
				margin: 16px 1.5% 0 0;
			}
			
			#home-page-associated-4 {;
				margin: 25px 0 0 0;
			}
		}
	}
	
}


/* Small Devices, Desktops */
@media only screen and (max-width : 991px) {
	
	.page-template-template-home {
		.landing-page-lg {
			.landing-page-content {
				#landing-page-banner-image {
					width: 100%;
				}
			}
		}
	}
	
	.landing-page-lg {
		#landing-page-banner-image {
			height: 459px;
		}
		
		.landing-page-content { 
			padding: 51px 0;
		}
	}
	
	.home-page-client-testimonial {
		height: auto;
		background-position: -204px -396px;
		padding: 0px 0 41px;
		
		#text-backgound {
			height: 46%;
			width: 100%;
			top:auto;
			bottom: 0;
		}
		
		h3 {
			margin: 0px 0 14px;
		}
		
		p {
			font-size: 19px;
			margin-bottom: 20px;
		}
	}
	
	.play-video-button-container {
	    height: 392px;
	}
	
	.col-5th {
		width:33.33333% !important;
	}
	
	/* MOBILE NAV  */ 
	.hamburger {
		float:right;
		padding: 6px 0;
		display: inline-block;
		cursor: pointer;
		transition-property: opacity, filter;
		transition-duration: 0.15s;
		transition-timing-function: linear;
		font: inherit;
		color: inherit;
		text-transform: none;
		background-color: transparent;
		border: 0;
		margin: 0;
		overflow: visible; 
		&:hover { 
			background:transparent!important;
			opacity: 0.7; 
		}
		&:focus {
			outline:none;
		}
	}
	
	.hamburger-box { 
		width: 30px; 
		height: 16px; 
		display: inline-block; 
		position: relative; 
		top:8px;
	}
	
	.hamburger-inner { 
		display: block; 
		top: 50%; 
		margin-top: -2px; 
	}
	
	.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
		width: 28px;
		height: 2px;
		background-color: $brand-blue;
		border-radius: 4px;
		position: absolute;
		transition-property: transform; 
		transition-duration: 0.15s; 
		transition-timing-function: ease; 
	}
	
	body.home {
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			background-color: $brand-blue;
		}
	}
	
	.hamburger-inner::before, .hamburger-inner::after { 
		content: ""; 
		display: block; 
	}
	
	.hamburger-inner::before {
		top: -8px;
	}
	
	.hamburger-inner::after {
		bottom: -8px;
	} 
	
	/** Collapse */
	.hamburger--collapse .hamburger-inner { 
		top: auto; 
		bottom: 0;
		transition-duration: 0.13s;
		transition-delay: 0.13s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	
	.hamburger--collapse .hamburger-inner::after { 
		top: -16px; 
		transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; 
	}
	
	.hamburger--collapse .hamburger-inner::before { 
		transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
	}
	
	.hamburger--collapse.is-active .hamburger-inner { 
		background-color: white;
		transform: translate3d(0, -10px, 0) rotate(-45deg);
		transition-delay: 0.22s;
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	
	.hamburger--collapse.is-active .hamburger-inner::after { 
		top: 0; 
		opacity: 0; 
		background-color: white; 
		transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 
	}
	
	.hamburger--collapse.is-active .hamburger-inner::before { 
		top: 0; 
		background-color: white;
		transform: rotate(-90deg); 
		transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); 
	}
	
	#navModal { 
		background-color: rgba($brand-blue, 0.95) !important; 
		color:white; 
		text-align: left; 
		overflow-y: hidden; 
		
		.close-white {
			margin:15px 25px 0 0; 
			color:white;
			opacity:0.9;
			
			&:hover {
				opacity:1;
			}
		}
	
		.menu-mobile-container ul { 
			padding: 0; 
			margin:100px 0 0 0; 
			list-style: none; 
			text-align: center;
			
			li { 
				margin:30px 0; 
				font-size:24px;
				
				a {
					color:white; 
				}
			}
		}
	}
	
	.modal-backdrop { 
		z-index: 1 !important; 
		background-color: rgba($brand-blue, 0.95) !important; 
	}
	
	.modal:before { 
		height: auto; 
	}
	
	h2 {
		font-size:28px;
	}
	
	.first-section-block {
		
		.teal-blob1 { 
			max-width:480px;
		}
	
		.jetman-container { 
			.blue-clouds { 
				left:-70px; 
			}
			
			.jetman { 
				right: 178px;
			}
		}
	}
	
	.mt-150 {
		margin:100px 0 60px 0;
	}
	
	.p-180 {
		padding:100px 0;
	}
	
	.card {
		width:100%;
	}
	
	.what-is-jp { 
		position: relative;
		
		.teal-blob1 { 
			max-width:600px;
		}
		
		.teal-blob2 { 
			max-width:200px;
		}
		
		.col-md-6 {
			
			.card:nth-child(1) {
				margin-top:0;
			}
			
			.card:nth-child(3) {
				clear:both;
			}
	
			.card:nth-child(4) {
				margin-top:15px;
			}
	
			.card:nth-child(odd) {
				margin-right:0%;
			}
		
			.card:nth-child(even) {
				margin-left:0%;
			}
		
			.card {
				margin-top:15px;
			}
		}
	}
	
	.services-we-offer {
		.center-align {
			display:block;
		}
	}
	
	.services-card { 
		padding:30px;  
		min-height:330px; 
		float:left;
	
		.services-img {
			width:100%; 
			margin-bottom:20px;
		}
	
		.services-info {
			width:100%;
		}
	} 
	
	.left-half-img, .right-half-content {
		height:490px; 
	}
	
	.left-half-img { 
		width:100%; 
	}
	
	.right-half-content { 
		background: rgba(255, 255, 255, .9); 
	}
	
	.game-card, .news-card, .key-feature {
		margin-bottom:40px;
	}
	
	.fourth-section-block  {
		blockquote, cite {
			font-size:24px;
		}
	}
	
	.large-intro-text {
		font-size:24px;
	}
	
	.pricing-card {
		.pricing-info { 
			padding:20px; 
			
			h3 {
				font-size:24px; 
			}
			
			h4 {
				font-size:28px;
			}
			
			h5 {
				font-size:19px;
			}
		}
	}
	
	.sample-demo {
		
		.demo-info-panel {
			
			.logo { 
				padding-left:20px; 
			}
			
			.demo-info { 
				h4 { 
					font-size:17px;
				}
			}
		}
	
		.demo-info-panel {
			
			.right-cta { 
				padding:20px;
				font-size:16px; 
			}  
		}
	
	}
	
	.template-card-container-hp { 
		&:nth-child(6n-1) {
			clear:both;
		}
		&:nth-child(8n-2) {
			clear:none;
		}
		&:nth-child(7n+1) {
			clear:none;
		}
	}
	
	.page-template-template-about {
		
		.landing-page-lg {
			.mainimg.center-align {
				.landing-page-content {				
					#landing-page-banner-image {
						display: none;
					}
				}
			}
		}
		
		.news-info {
			height: auto;
			p {
				display:none;
			}
		}
	}
	
	.page-template-template-contact {
		.landing-page-lg {
			.landing-page-content { 
				h1 {
					font-size: 26px;
				}
				
				p {
					font-size: 26px;
				}
			}
		}
	}
	
	.page-template-template-login-reg {
		.login-reg { 
			#loginForm {
				border-right: 0px solid white;
				border-bottom: 1px solid white;
				margin-bottom: 20px;
				width: 100%;
				
				.um-field-area {
					width: 100%;
				}
				
				#loginFormImage {
					display: none;
				}
			}
		}
	}
	
	.page-template-template-demos {
		.landing-page-lg {
			.landing-page-content {
				#landing-page-banner-image {
				    img {
					    max-width: 100%;
					}
				}
			}
		}
	}
	
	.home-page-associated {
		padding: 43px 0;
		
		ul {
			margin: 0;
			
			#home-page-associated-1 {
				width: 100%;
				margin: 18px 0;
			}
			
			#home-page-associated-2 {
				width: 100%;
				margin: 18px 0;
			}
			
			#home-page-associated-3 {
				width: 100%;
				margin: 18px 0;
			}
			
			#home-page-associated-4 {
				width: 100%;
				margin: 18px 0;
			}
		}
	}
	
}


/* Extra Small Devices, Tablets */
@media only screen and (max-width : 767px) {
	
	.page-template-template-home, .page-template-template-about, .page-template-template-product {
		.landing-page-lg {
			background-size: cover;
			.mainimg.center-align {
			    background-color: rgba(19, 44, 55, 0.4);
			}
		}
	}
	
	.home-page-client-testimonial {
    	background-position: -203px -360px;
    	padding: 0 0 20px 0 !important;
    	
    	#text-backgound {
			height: 48%;
		}
    }
	
	.landing-page-lg {
		#landing-page-banner-image {
			height: 380px;
			width: 100%;
			opacity: 0.4;
		}
		
		.landing-page-content { 
			padding: 51px 0;
			
			h1 {
				font-size: 32px;
				width: auto;
			}
		}
	}
	
	.page-template-template-demos {
		.landing-page-lg {
			.landing-page-content {
				#landing-page-banner-image {
				    opacity: 1;
				}
			}
		}
	}
	
	.page-template-template-product {
		.landing-page-lg {
			.mainimg.center-align {
				background-color: rgba(19,44,55,0);
			}
			
			.landing-page-content {
				#landing-page-banner-image {
					    opacity: 1;
				}
			}
		}
	}
	
	.hamburger {
		padding: 2px 0;
	}
	
	h1 {
		font-size:28px;
	}
	
	h2 {
		font-size:24px;
	}
	
	h3 {
		font-size:21px;
	}
	
	h4 {
		font-size:18px;
	}
	
	.container {
		padding: 0 25px;
	}
	
	.content-section {
		padding:40px 0;
	}
	
	.landing-page-content { 
		padding:150px 0 100px 0; 
		
		h1 {
			font-size:36px;
		}
		
		h2 {
			font-size:26px; 
			margin:10px 0;
		}
	}
	
	.jetman-container {
		display:none;
	}
	
	.about-first-section-block {
		.blue-blob1 {
			max-width:400px;
		}
		
		.blue-blob2 {
			max-width:300px;
		}
	}
	
	.what-is-jp {
		.teal-blob1 {
			max-width:220px; 
			top: -50px;
		}
	}
	
	.services-card  { 
		min-height:auto; 
		height:auto; 
		float:left; 
		
		.services-img img {
			margin-top:0;
		}
	}
	
	.blog-article {
		.teal-blob1 { 
			max-width:200px;
		}
		.teal-blob2 { 
			max-width:80px;
		}
	}
	
	.landing-subpage-lg {
		padding-top: 50px; 
		padding-bottom: 50px;
	}
	
	.ctcc-inner  {
		font-size:14px;
		
		a {
			font-size:14px;
		}
	}
	
	.pricing-card {
		margin:20px 0;
	}
	
	.sample-demo {
		.demo-info-panel {
		    .demo-info { 
			    float:left; 
			    text-align: left; 
			    padding: 0;
				
				h4 { 
					font-size:14px;
				}
		    }
		}
	
		.demo-info-panel {
	    	.right-cta {
		    	font-size:14px; 
		    	float: right;
		    }  
		}
	}
	
	.col-5th {
		width:50% !important;
	}
	
	.col-lg, .col-sm, .col-xs {
		width:100%;
	}
	
	.contentLabel {
		display:none;
	}
	
	#gameContent1 {
		input {
			width:90%; 
			float:left; 
			margin:5px 0 !important;
		}
		label {
			width:10%; 
			float:left; 
			margin:0;
		}
	}
	
	.removeQuestion {
		margin:0 0 30px 0; 
		width:100%;
	}
	
	.page-template-template-about {
		.landing-page-lg {
			.mainimg.center-align {
				.landing-page-content {
					h1, h2, .cta-container {
						padding: 0 ;
					}
				}
			}
		}
	}
	
}


/* Extra Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
	body.home header, header {
		padding:25px 0;
	}
	
	.container {
		padding: 0 20px;
	}

	p {
		font-size:18px;
	}
	
	.content-section {
		padding:35px 0;
	}
	
	.text-center {
		margin-bottom:20px;
	}

	.landing-page-content { 
		padding:90px 0 50px 0;
		
		h1 {
			font-size:28px;
		}
		
		h2 {
			font-size:21px;
		}
	 }
	
	.mt-150 {
		margin:50px 0 0 0;
	}
	
	.cta-red-border {
		margin:0;
	}
	
	.news-card .news-info {
		min-height: 220px;
	}
	
	.game-card, .news-card, .key-feature {
		margin-bottom:25px;
	}
	
	.bottom-card-cta {
		padding:25px; 
		
		h3 {
			margin-bottom:5px;
		}
		
		&.center-align {
			display:block;
		}
		
		p {
			font-size:18px;
		}
		
		.card-content, .card-cta  {
			width:100%; 
			margin:0;
		}
		
		.cta-red {
			margin:0;
		}
	 }
	
	.left-half-img, .right-half-content { 
		height:450px; 
		
		a {
			margin-top:0;
		}
	}
	
	.landing-subpage-lg {
		padding-top: 50px; 
		padding-bottom: 50px;
	}
	
	.blog-article {
		.teal-blob1 { 
			max-width:150px;
		}
		.teal-blob2 { 
			max-width:50px;
		}
	}	
	
	.game-card .game-info, .news-card .news-info  {
		h4 {
			font-size:21px; 
			margin-bottom:5px;
		}
	}
	
	.fourth-section-block  {
		blockquote {
			font-size:19px;
		}
		cite {
			font-size:17px;
		}
	}
	
	.large-intro-text {
		font-size:21px;
	}
	
	.pricing-card {
		.pricing-info { 
			padding:25px; 
			
			h3 {
				font-size:21px;
			}
			h4 {
				font-size:24px;
			}
			h5 {
				font-size:18px;
			}
		}
	}
	
	.sample-demo {
		.demo-info-panel { 
		    .demo-info { 
			    width:60%; 
			    padding-left:15px;
			}
		}
	}
	
	.col-5th {
		width:100% !important;
	}
	
	.container-fluid {
		padding:0 30px;
	}
}

