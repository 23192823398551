
// MIXINS
// ------------------------------------------
/* Gradient - Left to Right */
@mixin landing-image-grad($color1, $opacity1, $color2, $opacity2) {
  position: relative;
  float: left;
  z-index: 2;
  width: 100%;
  height: 100%;
  color:white;
  &:before{
    background: $color1;
    background: -webkit-linear-gradient(right bottom, rgba($color1, $opacity1), rgba($color2, $opacity2)); 
    background: -o-linear-gradient(right bottom , rgba($color1, $opacity1), rgba($color2, $opacity2));
    background: -moz-linear-gradient(right bottom, rgba($color1, $opacity1), rgba($color2, $opacity2));
    background: linear-gradient(to right bottom, rgba($color1, $opacity1) , rgba($color2, $opacity2)); 
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}


/* Solid button */
@mixin solid-btn($color-bg, $color-text, $color-text-hover) {
	background: $color-bg; /* For browsers that do not support gradients */
	display: inline-block;
	padding:10px 25px;
	margin-top:15px;
	font-size:25px;
	font-weight:700;
	font-family:$font-stack2;
	color:$color-text;
	border-radius: 50px;
	transition: all .15s ease;
	&:hover {
		background: lighten($color-bg, 5%) !important; 
		color:$color-text-hover;
		text-decoration: none;
		transform: translateY(-1px);
		box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 4px 8px rgba(0,0,0,.08);
	}
	&:active{
		color:$color-text-hover; 
		text-decoration: none;
	}
	&:focus {
		color:$color-text-hover; 
		background:lighten($color-bg,10%); 
		text-decoration: none; 
	}
	@media only screen and (max-width : 480px) {
    	font-size:14px; 
    	padding:8px 15px;
	}
}

/* Solid button border */
@mixin solid-btn-border($color-bg, $color-text, $color-text-hover) {
  background: transparent;
  display: inline-block;
  padding:10px 25px;
  margin-top:15px;
  font-size:16px;
  font-weight:500;
  text-transform: uppercase;
  color:$color-text;
  border-radius: 50px;
  border:2px solid $color-bg;
  transition: all .15s ease;
  &:hover {
    color:$color-text-hover;
    text-decoration: none;
    transform: translateY(-1px);
  }
  &:active{color:$color-text-hover !important;}
  &:focus {color:$color-text-hover !important; }
  @media only screen and (max-width : 480px) {
    font-size:14px; padding:8px 15px;
  }
}


/* quick float */
@mixin floatW {
	float:left; width:100%;
}

/* background covers */
@mixin bgCover {
	background-repeat:no-repeat; 
	background-position:center center;  
	-webkit-background-size: cover; 
	-moz-background-size: cover; 
	-o-background-size: cover; 
	background-size: cover;
}


/* small text shadow */
@mixin sm-text-shadow {
  text-shadow: 0px 1px 3px rgba(0,0,0,.3);
}



/* Box shadowing */
@mixin boxShadow {
  box-shadow: 0 2px 4px rgba(0,0,0,.16);
  transition: all .15s ease;
  &:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    cursor: pointer;
  }
}

/* quick float */
@mixin coloured-section($color-bg) {
  float:left; width:100%; background-color:$color-bg; padding:75px 0;
}


/* cards */
@mixin card() {
  border-radius:10px; box-shadow: 0 4px 6px rgba(55,55,55,.3); position: relative;  z-index: 1; background: white; transition: all .15s ease;
  &:hover {
    text-decoration: none;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(55,55,55,.3), 0 4px 8px rgba(55,55,55,.3);
  }
  a {
    &:hover {color:$brand-text; text-decoration:none;}
    &:active {color:$brand-text; text-decoration:none;}
    &:focus {color:$brand-text; text-decoration:none;}
  }
  span {color:$brand-primary; font-family: $font-stack2; font-weight:700;  border-bottom: 2px solid $brand-primary; padding-bottom: 5px; text-transform: uppercase;}
}


